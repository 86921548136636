import React from "react";
import "./Blogs.css";

const BlogsLoading = () => {
  return (
    <div className="blog-card0 loader">
      <div
        style={{ width: "25rem", height: "8rem", backgroundColor: "lightgrey" }}
      ></div>
      <div>
        <h2
          style={{
            marginTop: "1rem",
            backgroundColor: "lightgray",
            width: "20rem",
          }}
        > </h2>
        <div
          style={{
            backgroundColor: "lightgray",
            width: "16rem",
            height: "3rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default BlogsLoading;
