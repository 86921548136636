import { useEffect, useState } from "react";
import supabase from "../supabase";

function useSupabase(itemName, initialValue) {
  const [item, setItem] = useState(initialValue);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  async function fetchAllData() {
    let { data: items, error } = await supabase
      .from(itemName)
      .select("*")
      .order("id", { ascending: false });
    if (error) {
      setError(true);
      console.warn(error);
    }
    setItem(items);
  }

  useEffect(() => {
    fetchAllData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { item, loading, error, setItem };
}

export { useSupabase };
