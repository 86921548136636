import React, { createContext, useState } from "react";
import { useSupabase } from "./hooks/useSupabase";

const Context = createContext();

const ContextProvider = ({ children }) => {
  const {
    item: data,
    setItem: setData,
    loading: dataLoading,
  } = useSupabase("associates", []);
  const {
    item: articles,
    setItem: setArticles,
    loading: articlesLoading,
  } = useSupabase("posts", []);

  const [article, setArticle] = useState();

  return (
    <Context.Provider
      value={{
        data,
        dataLoading,
        article,
        articles,
        articlesLoading,
        setData,
        setArticle,
        setArticles,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
