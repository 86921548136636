import React, { useState } from "react";
import "./Login.css";
import { TiArrowLeft } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import supabase from "../../supabase.js";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //useNavigate es un hook que permite manipular el historial del navegador, se puede cambiar la URL de la página actual sin necesidad de recargarla
  const navigate = useNavigate();

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    let { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error != null) {
      alert("Usuario o contraseña incorrectos");
      console.log(error);
    } else {
      setCookie("token", data.session.access_token, 7);
      navigate("/admin");
    }
  };

  return (
    <div className="container">
      <Link to="/">
        <span>
          <TiArrowLeft className="icon" size="65px" color="#421400" />
        </span>
      </Link>
      <section>
        <div className="login-title">
          <h1>Iniciar Sesión</h1>
        </div>
        <div className="login-form">
          <form action="login" onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <button type="submit" className="login-btn">
              Log In
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
