import React, { useEffect, useState } from "react";
import "./Home.css";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import About from "../../components/about/About";
import Footer from "../../components/footer/Footer";
import ScrollToTopButton from "../../components/autoscroll/ScrollToTopButton";
import Phrase from "../../components/phrase/Phrase";
import Carousel from "../../components/reel/Carousel.jsx";
import Schedule from "../../components/schedule/Schedule";
import Blogs from "../../components/blogs/Blogs";
import supabase from "../../supabase.js";

const Home = () => {
  const [articles, setArticles] = useState([]);

  async function fetchArticles() {
    let { data: posts, error2 } = await supabase.from("posts").select("*");
    if (!error2) {
      setArticles(posts);
    }
  }

  useEffect(() => {
    fetchArticles();
  }, []);

  return (
    <>
      <div className="background-header-banner">
        <div className="banner-filter"></div>
        <Header />
        <Banner />
      </div>
      <About />
      <Phrase />
      <Carousel />
      <Schedule />
      <Blogs articles={articles} />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Home;
