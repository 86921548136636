import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaTrash } from "react-icons/fa/";
import supabase from "../../../supabase";

function DeleteArticle({ articleId, articleName }) {
  const [show, setShow] = useState(false);

  const handleDelete = async (id) => {
    const { error } = await supabase.from("posts").delete().eq("id", id);
    if (error) {
      alert("Ocurrió un error eliminando el articulo");
    }
    window.location.reload();
  };

  return (
    <div>
      <Button variant="danger" onClick={() => setShow(true)}>
        <FaTrash />
      </Button>
      <Modal show={show}>
        <Modal.Header closeButton onClick={() => setShow(false)}>
          <Modal.Title>Eliminar Articulo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Seguro que deseas eliminar "{articleName}"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={() => handleDelete(articleId)}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteArticle;
