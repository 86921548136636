import React from "react";
import { Navigate, Outlet } from "react-router";
import jwt_decode from "jwt-decode";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const ProtectedRoutes = ({ children }) => {
  const jwt = getCookie("token");
  let decoded = null;
  try {
    decoded = jwt_decode(jwt);
  } catch (error) {
    return <Navigate to="/login" />;
  }

  const expiryDate = new Date(decoded.exp * 1000);

  if (jwt && expiryDate > Date.now()) {
    return children ? children : <Outlet />;
  }

  return <Navigate to="/login" />;
};

export default ProtectedRoutes;
