import React from "react";
import "./Grid.css";

const Grid = () => {
  return (
    <div className="schedule">
      <div className="time">Hora</div>
      <div className="day">Lunes</div>
      <div className="day">Martes</div>
      <div className="day">Miércoles</div>
      <div className="day">Jueves</div>
      <div className="day">Viernes</div>
      <div className="time">10:00</div>
      <div className="course"></div>
      <div className="course">Tai Chi</div>
      <div className="course"></div>
      <div className="course">Tai Chi</div>
      <div className="course"></div>
      <div className="time">14:00</div>
      <div className="course">Tai Chi</div>
      <div className="course">Kung Fu Adultos</div>
      <div className="course">Tai Chi</div>
      <div className="course">Kung Fu Adultos</div>
      <div className="course"></div>
      <div className="time">18:00</div>
      <div className="course">Kung Fu Kids e Infantil</div>
      <div className="course">Entrenamiento Fisico</div>
      <div className="course">Kung Fu Kids e Infantil</div>
      <div className="course">Entrenamiento Fisico</div>
      <div className="course"></div>
      <div className="time">19:00</div>
      <div className="course">Tai Chi</div>
      <div className="course">Kung Fu Juvenil</div>
      <div className="course">Tai Chi</div>
      <div className="course">Kung Fu Juvenil</div>
      <div className="course">Actividades Especiales</div>
      <div className="time">20:00</div>
      <div className="course">Kung Fu Adultos</div>
      <div className="course">Tai Chi /<br />Kung Fu Adultos</div>
      <div className="course">Kung Fu Adultos</div>
      <div className="course">Tai Chi /<br />Kung Fu Adultos</div>
      <div className="course"></div>
      <div className="time">21:00</div>
      <div className="course">Kung Fu Avanzado</div>
      <div className="course"></div>
      <div className="course">Kung Fu Avanzado</div>
      <div className="course"></div>
      <div className="course"></div>
    </div>
  );
};

export default Grid;
