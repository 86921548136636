import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TiArrowLeft } from "react-icons/ti";
import { Table } from "react-bootstrap";
import DeleteArticle from "../../../components/admin/article/DeleteArticle";
import CreateArticle from "../../../components/admin/article/CreateArticle";
import HTMLReactParser from "html-react-parser";
import { Context } from "../../../Context";

function ArticlePanel() {
  const { articles } = useContext(Context);

  return (
    <section
      className="container justify-content-center form-container"
      style={{ backgroundColor: "#fffcf0" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to="/admin">
          <span>
            <TiArrowLeft className="icon" size="65px" color="#421400" />
          </span>
        </Link>
        <CreateArticle />
      </div>
      <h1>Artículos</h1>
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Título</th>
            <th>Contenido</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {articles &&
            articles.map((article, index) => {
              return (
                <tr key={index} style={{ height: "4rem" }}>
                  <td>{article.id}</td>
                  <td>{article.title}</td>
                  <td>
                    {HTMLReactParser(article.content.substring(0, 80) + "...")}
                  </td>
                  <td style={{ display: "flex", gap: "0.5rem" }}>
                    <CreateArticle edit={true} article={article} />
                    <DeleteArticle
                      articleId={article.id}
                      articleName={article.title}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </section>
  );
}

export default ArticlePanel;
