import React, { useContext } from "react";
import { truncateText } from "../../utils/Utils";
import { Context } from "../../Context";
import "./Blogs.css";
import { Link } from "react-router-dom";
import BlogsLoading from "./BlogsLoading";

const Blogs = () => {
  const { setArticle, articles, articlesLoading } = useContext(Context);

  return (
    <section className="blogs-container" id="publicaciones">
      <h2>Publicaciones</h2>
      <div className="blogs-box">
        {articlesLoading && <BlogsLoading />}
        {articles &&
          articles.slice(0, 6).map((article, i) => {
            return (
              <div className="blog-card0" key={"blog-" + i}>
                <Link
                  onClick={() => setArticle(article)}
                  to={`/articulos/${article.id}`}
                >
                  <img src={article.image} alt="Portada del artículo" />
                  <div>
                    <div>
                      <h2>{article.title}</h2>
                      {truncateText(article.content, 150)}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
      <button>Ver todos los artículos</button>
      {/* <section className="blogs-container" id="publicaciones">
        <h2>Publicaciones</h2>
        <div className="blogs-box">
          <div className="blog-card0">
            <Link
              onClick={() => setArticle(articles[0])}
              to={`/articulos/${articles[0].id}`}
            >
              <img src={articles[0].image} alt="Portada del artículo" />
              <div>
                <div>
                  <h2>{articles[0].title}</h2>
                  <p>{truncateText(articles[0].content, 150)}</p>
                </div>
                <p>Ver más</p>
              </div>
            </Link>
          </div>
          <div className="blog-card0">
            <Link
              onClick={() => setArticle(articles[1])}
              to={`/articulos/${articles[1].id}`}
            >
              <img src={articles[1].image} alt="Portada del artículo" />
              <div>
                <div>
                  <h2>{articles[1].title}</h2>
                  <p>{truncateText(articles[1].content, 150)}</p>
                </div>
                <Link
                  onClick={() => setArticle(articles[1])}
                  to={`/articulos/${articles[1].id}`}
                  className="moreButton"
                >
                  Ver más
                </Link>
              </div>
            </Link>
          </div>
          <div className="blog-card0">
            <Link
              onClick={() => setArticle(articles[2])}
              to={`/articulos/${articles[2].id}`}
            >
              <img src={articles[2].image} alt="Portada del artículo" />
              <div>
                <div>
                  <h2>{articles[2].title}</h2>
                  <p>{truncateText(articles[2].content, 150)}</p>
                </div>
                <Link
                  onClick={() => setArticle(articles[2])}
                  to={`/articulos/${articles[2].id}`}
                  className="moreButton"
                >
                  Ver más
                </Link>
              </div>
            </Link>
          </div>
        </div>

        <Link onClick={() => setArticle(articles[1])} to="/articulos">
          Más artículos
        </Link>
      </section> */}
      {/* {articles && (
        <section className="blogs-container" id="publicaciones">
          <h2>Publicaciones</h2>
          <div className="blogs-box">
            <div className="blog-card0">
              <Link
                onClick={() => setArticle(articles[0])}
                to={`/articulos/${articles[0].id}`}
              >
                <img src={articles[0].image} alt="Portada del artículo" />
                <div>
                  <div>
                    <h2>{articles[0].title}</h2>
                    <p>{truncateText(articles[0].content, 150)}</p>
                  </div>
                  <Link
                    onClick={() => setArticle(articles[0])}
                    to={`/articulos/${articles[0].id}`}
                    className="moreButton"
                  >
                    Ver más
                  </Link>
                </div>
              </Link>
            </div>
            <div className="blog-cards-container">
              {articles &&
                // eslint-disable-next-line
                articles.map((a, i) => {
                  if (i !== 0 && i < 3) {
                    return (
                      <>
                        <div
                          className={`blog-card${i} blog-card`}
                          style={{
                            backgroundImage: "url(" + articles[i].image + ")",
                          }}
                        >
                          {i === 2 ? (
                            <Link
                              onClick={() => setArticle(articles[1])}
                              to="/articulos"
                              className="card-link"
                            >
                              <h2>
                                <Link
                                  onClick={() => setArticle(articles[1])}
                                  to="/articulos"
                                >
                                  Más artículos
                                </Link>
                              </h2>
                              <div className="blog-filter"> </div>
                            </Link>
                          ) : (
                            <Link
                              onClick={() => setArticle(articles[1])}
                              to={`/articulos/${articles[1].id}`}
                              className="card-link"
                            >
                              <div className="blog-filter"> </div>
                              <h2>
                                <Link
                                  onClick={() => setArticle(articles[1])}
                                  to={`/articulos/${articles[1].id}`}
                                >
                                  {a.title}
                                </Link>
                              </h2>
                            </Link>
                          )}
                        </div>
                        {articles[1] && !articles[2] && (
                          <div
                            className={`blog-card${i} blog-card`}
                            style={{
                              backgroundImage: "url(" + articles[i].image + ")",
                            }}
                          >
                            {" "}
                            <Link
                              onClick={() => setArticle(articles[1])}
                              to="/articulos"
                              className="card-link"
                            >
                              <h2>
                                <Link
                                  onClick={() => setArticle(articles[1])}
                                  to="/articulos"
                                >
                                  Más artículos
                                </Link>
                              </h2>
                              <div className="blog-filter"> </div>
                            </Link>{" "}
                          </div>
                        )}
                      </>
                    );
                  } else if (i === 0 && !articles[1]) {
                    return (
                      <>
                        <div
                          className={`blog-card1 blog-card`}
                          style={{
                            backgroundImage: "url(" + articles[i].image + ")",
                          }}
                        >
                          <Link
                            onClick={() => setArticle(articles[0])}
                            to="/articulos"
                            className="card-link"
                          >
                            <div className="blog-filter"> </div>
                          </Link>
                        </div>

                        <div
                          className={`blog-card2 blog-card`}
                          style={{
                            backgroundImage: "url(" + articles[i].image + ")",
                          }}
                        >
                          <Link
                            onClick={() => setArticle(articles[0])}
                            to="/articulos"
                            className="card-link"
                          >
                            <h2>
                              <Link
                                onClick={() => setArticle(articles[0])}
                                to="/articulos"
                              >
                                Más artículos
                              </Link>
                            </h2>
                            <div className="blog-filter"> </div>
                          </Link>
                        </div>
                      </>
                    );
                  }
                })}
            </div>
          </div>
        </section>
      )} */}
    </section>
  );
};

export default Blogs;
